@import 'ht-variables';
@import 'ht-typography';

ul.ngx-pagination {
  padding-inline-start: 0px !important;

  .pagination-next a::after,
  .pagination-next.disabled::after {
    content: '>';
    margin-left: 0.1rem !important;
  }
  .pagination-previous a::before,
  .pagination-previous.disabled::before {
    content: '<';
    margin-right: 0.1rem !important;
  }

  li {
    @extend .text-body-small;
    border: transparent !important;
    border-radius: 4px !important;
    color: $text-contrast !important;
    cursor: default;

    a:hover {
      border-radius: 4px !important;
      background: $L50 !important;
      &:active {
        background: $L100 !important;
        border-radius: 4px !important;
      }
    }
    &.current {
      background: $L200 !important;
    }

    &.disabled {
      color: $N300 !important;
    }
  }
}
