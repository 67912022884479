/* Colors */

$W000: #ffffff;

/*Neutral*/
$N900: #24252c;
$N800: #2e3038;
$N700: #393b45;
$N600: #50525f;
$N500: #666a7a;
$N400: #84889d;
$N300: #9b9daf;
$N200: #c4c6d0;
$N100: #e8e8ea;
$N50: #f0f0f3;
$N25: #f8f8fa;

/*Lilara*/
$L900: #10003d;
$L800: #200066;
$L700: #321189;
$L600: #522ec6;
$L500: #6e49ff;
$L400: #8b6dff;
$L300: #a993ff;
$L200: #cbbdff;
$L100: #e8e2ff;
$L50: #f4f1ff;

/*Aqua*/
$A900: #1e4c4c;
$A800: #286464;
$A700: #327d7d;
$A600: #43a8a8;
$A500: #54d2d2;
$A400: #76dbdb;
$A300: #98e4e4;
$A200: #c1efef;
$A100: #e4f8f8;
$A50: #f2fbfb;

/*Green*/
$G900: #144725;
$G800: #1b5e31;
$G700: #21753d;
$G600: #2d9e52;
$G500: #38c566;
$G400: #60d185;
$G300: #87dca3;
$G200: #b7eac8;
$G100: #e0f6e7;
$G50: #effaf3;

/*Yellow*/
$Y900: #5c4900;
$Y800: #7a6100;
$Y700: #987900;
$Y600: #cca200;
$Y500: #ffcb00;
$Y400: #ffd533;
$Y300: #ffe065;
$Y200: #ffeca3;
$Y100: #fff7d7;
$Y50: #fffbeb;

/*Orange*/
$O900: #5c3715;
$O800: #7a491c;
$O700: #985b23;
$O600: #cc7a2f;
$O500: #ff983b;
$O400: #ffad62;
$O300: #ffc189;
$O200: #ffdab8;
$O100: #ffefe0;
$O50: #fff7f0;

/*Red*/
$R900: #5c1313;
$R800: #7a1a1a;
$R700: #982020;
$R600: #cc2b2b;
$R500: #ff3636;
$R400: #ff5e5e;
$R300: #ff8686;
$R200: #ffb6b6;
$R100: #ffdfdf;
$R50: #ffefef;

/*Pink*/
$P900: #5c1828;
$P800: #7a2035;
$P700: #982742;
$P600: #cc3559;
$P500: #ff426f;
$P400: #ff688c;
$P300: #ff8da8;
$P200: #ffbbcb;
$P100: #ffe1e8;
$P50: #fff0f4;

/* Text colors */
$text-disabled-neutral: $N300;
$text-base: $N700;
$text-subtle: $N500;
$text-positive: $G700;
$text-negative: $P700;
$text-alert: $O800;
$text-warning: $Y800;
$text-info: $A800;
$text-contrast: $N900;
/* Navbar */
$navbar-width: 209px;
$navbar-button-width: 14px;
$navbar-button-height: 14px;

$navbar-width-collapse: 58px;
$navbar-button-collapse-width: 42px;
$navbar-button-collapse-height: 42px;

/* Scren sizes */
$media-width-sm: 600px;
$media-width-md: 960px;
$media-width-l: 1400px;
$media-width-xl: 1920px;

$input-max-width: 280px;

/* Box Shadows */
$box-shadow-050:
  0px 0px 0px 1px rgba(255, 255, 255, 0),
  0px 6px 12px -3px rgba(66, 74, 83, 0.04),
  0px 6px 18px 0px rgba(66, 74, 83, 0.12);

/* Border radius */
$radius-xxs: 2px;
$radius-xs: 4px;
$radius-s: 6px;
$radius-sm: 8px;
$radius-m: 10px;
$radius-ml: 12px;
$radius-l: 16px;

/* Line heights */
$line-height-xxs: 1rem;
$line-height-xs: 1.25rem;
$line-height-s: 1.375rem;
$line-height-m: 1.5rem;
$line-height-ml: 2rem;
$line-height-l: 2.125rem;
$line-height-xl: 3.5rem;
$line-height-2xl: 4.375rem;
$line-height-3xl: 5.625rem;

/* Spacings */
$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-sm: 16px;
$spacing-m: 20px;
$spacing-ml: 24px;
$spacing-l: 32px;
$spacing-xl: 40px;
$spacing-xxl: 48px;
$spacing-xxxl: 56px;

/* Font sizes */
$font-size-xxs: 0.625rem;
$font-size-xs: 0.75rem;
$font-size-s: 0.875rem;
$font-size-sm: 1rem;
$font-size-m: 1.125rem;
$font-size-ml: 1.25rem;
$font-size-l: 1.5rem;
$font-size-xl: 2.5rem;
$font-size-xxl: 3.5rem;
$font-size-xxxl: 4.5rem;
