@import 'ht-dropdown.scss';

@mixin dropdown-list {
  @extend .dropdown-menu;
  transform: translate(33px, -42px);

  a {
    @extend .dropdown-menu-item;
  }
}

.ht-nav {
  padding-bottom: 5px;
  width: $navbar-width;
  min-width: $navbar-width;
  background-color: $N50;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &.collapse {
    width: $navbar-width-collapse;
    min-width: $navbar-width-collapse;
  }

  .hrline {
    border-top: 1px solid $N100;
    margin: $spacing-xs $spacing-xs $spacing-xs 0px;
  }
}

.ht-nav-btn {
  display: flex;
  align-items: center;
  gap: $spacing-xxs;
  padding: $spacing-xxs;
  i {
    color: $N700;
    font-size: 14px;
  }
  label {
    @extend .text-small;
    @extend .bold;
  }
}

.ht-nav-menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: $spacing-s;
}

.ht-nav-list {
  display: flex;
  flex-direction: column;
  margin-left: $spacing-sm;
  z-index: 101 !important;

  a {
    padding: 2px $spacing-xs;
    border-radius: 4px;
    color: $N800;
    margin-left: $spacing-sm;
    @extend .text-body-small;
    width: -webkit-fill-available;

    &:hover {
      background-color: $N100;
    }
    &.active {
      background-color: $N700;
      color: $N50;
      @extend .bold;
    }
  }
}

.ht-nav-sub {
  padding: $spacing-xs;
  flex: 1 1 100%;
  place-content: center space-between;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  max-height: 100%;
}

.ht-btn-collapse {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 0.06px;
  justify-content: left;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xxs;
  align-items: center;
  padding-left: $spacing-xs;
  i {
    font-size: 9px;
    color: $N300;
    /*To center with span*/
    padding-bottom: 1px;
  }
  span {
    padding-left: 6px;
    color: $N300 !important;
    @extend .text-small;
  }
}
.ht-nav-help {
  i {
    color: $N300;
    font-size: 14px;
  }
  span {
    color: $N300 !important;
    @extend .text-small;
  }
}

.ht-nav-bottom {
  width: 100%;
}

/*Button Profile*/
.ht-nav-item-profile {
  .ht-nav-btn-arrows {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding-right: $spacing-xxs;
  }
  .ht-nav-btn {
    padding: $spacing-xxs $spacing-xs;
    gap: $spacing-xs;
    border-radius: 4px;
    .ht-nav-username {
      display: flex;
      flex-direction: column;
      font-weight: 400;
      cursor: pointer;
      width: 65%;
    }

    &:hover {
      background-color: $N100;
      cursor: pointer;
    }
    i {
      font-size: 10px;
    }
  }
  &.ht-nav-item:hover .ht-nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ht-nav-list {
    @include dropdown-list;
    &.ht-nav-list-pulltop {
      transform: translate(-15px, -168px);
      width: 245px;
    }

    a {
      display: block;
      @extend .no-wrap;
    }
    width: 245px;
  }
}

/*Collapse*/
.collapse {
  .ht-btn-collapse {
    padding-left: 0px;
    justify-content: center;
    padding-bottom: $spacing-sm;
  }

  .ht-nav-sub {
    padding-right: 0px;
  }

  .ht-nav-btn {
    width: $navbar-button-collapse-width;
    height: $navbar-button-collapse-height;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0px;
    margin: 0 auto;
    align-self: center;

    &:hover {
      background-color: $N100;
      cursor: pointer;
    }
    &.active {
      background-color: $N800;

      i {
        color: white;
      }
    }
    i {
      color: $N900;
      font-size: 18px;
    }
    label {
      display: none;
    }
  }

  .ht-nav-help {
    i {
      color: $N300;
      font-size: 18px;
    }
  }

  .ht-nav-item {
    padding-right: $spacing-xs;
  }
  .ht-nav-item:hover .ht-nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .ht-nav-menu {
    gap: $spacing-xxs;
  }
  .ht-nav-item-profile {
    .ht-nav-btn-arrows {
      display: none;
    }
    .ht-nav-username {
      display: none;
    }
    .ht-nav-btn {
      padding: 0;
      padding-right: $spacing-xxs;
    }
  }

  .ht-nav-list {
    @include dropdown-list;
    width: 193px;
    &.ht-nav-list-pulltop {
      transform: translate(33px, -120px) !important;
    }
  }
}
